export const colors = {
    mainColor: '#c4a035',
    secondColor: '#dbc684',
    thirdColor: '#f8f4e8',
    whiteColor: '#ffffff',
    darkColor: '#414042',
    blackColor: 'black',
    grayColor1: '#a3a3a3',
    grayColor2: '#858585',
    redColor: 'red',
  }