export const countries = [
    { label: "AE - United Arab Emirates Dirham (د.إ)", value: "aed" },
    { label: "AF - Afghan Afghani (؋)", value: "afn" },
    { label: "AL - Albanian Lek (Lek)", value: "all" },
    { label: "AM - Armenian Dram (֏)", value: "amd" },
    { label: "AN - Netherlands Antillean Guilder (ƒ)", value: "ang" },
    { label: "AO - Angolan Kwanza (Kz)", value: "aoa" },
    { label: "AR - Argentine Peso ($)", value: "ars" },
    { label: "AU - Australian Dollar ($)", value: "aud" },
    { label: "AW - Aruban Florin (ƒ)", value: "awg" },
    { label: "AZ - Azerbaijani Manat (₼)", value: "azn" },
    { label: "BA - Bosnia-Herzegovina Convertible Mark (KM)", value: "bam" },
    { label: "BB - Barbadian Dollar ($)", value: "bbd" },
    { label: "BD - Bangladeshi Taka (৳)", value: "bdt" },
    { label: "BG - Bulgarian Lev (лв)", value: "bgn" },
    { label: "BH - Bahraini Dinar (ب.د)", value: "bhd" },
    { label: "BI - Burundian Franc (FBu)", value: "bif" },
    { label: "BM - Bermudian Dollar ($)", value: "bmd" },
    { label: "BN - Brunei Dollar ($)", value: "bnd" },
    { label: "BO - Bolivian Boliviano (Bs.)", value: "bob" },
    { label: "BR - Brazilian Real (R$)", value: "brl" },
    { label: "BS - Bahamian Dollar ($)", value: "bsd" },
    { label: "BT - Bhutanese Ngultrum (Nu.)", value: "btn" },
    { label: "BW - Botswanan Pula (P)", value: "bwp" },
    { label: "BY - Belarusian Ruble (Br)", value: "byn" },
    { label: "BZ - Belize Dollar (BZ$)", value: "bzd" },
    { label: "CA - Canadian Dollar ($)", value: "cad" },
    { label: "CD - Congolese Franc (FC)", value: "cdf" },
    { label: "CH - Swiss Franc (Fr.)", value: "chf" },
    { label: "CL - Chilean Peso ($)", value: "clp" },
    { label: "CN - Chinese Yuan (¥)", value: "cny" },
    { label: "CO - Colombian Peso ($)", value: "cop" },
    { label: "CR - Costa Rican Colón (₡)", value: "crc" },
    { label: "CU - Cuban Peso (₱)", value: "cup" },
    { label: "CV - Cape Verdean Escudo (Esc)", value: "cve" },
    { label: "CZ - Czech Republic Koruna (Kč)", value: "czk" },
    { label: "DJ - Djiboutian Franc (Fdj)", value: "djf" },
    { label: "DK - Danish Krone (kr)", value: "dkk" },
    { label: "DO - Dominican Peso (RD$)", value: "dop" },
    { label: "DZ - Algerian Dinar (د.ج)", value: "dzd" },
    { label: "EG - Egyptian Pound (£)", value: "egp" },
    { label: "ER - Eritrean Nakfa (Nfk)", value: "ern" },
    { label: "ET - Ethiopian Birr (Br)", value: "etb" },
    { label: "EU - Euro (€)", value: "eur" },
    { label: "FJ - Fijian Dollar ($)", value: "fjd" },
    { label: "FK - Falkland Islands Pound (£)", value: "fkp" },
    { label: "GB - British Pound Sterling (£)", value: "gbp" },
    { label: "GE - Georgian Lari (₾)", value: "gel" },
    { label: "GG - Guernsey Pound (£)", value: "ggp" },
    { label: "GH - Ghanaian Cedi (₵)", value: "ghs" },
    { label: "GI - Gibraltar Pound (£)", value: "gip" },
    { label: "GM - Gambian Dalasi (D)", value: "gmd" },
    { label: "GN - Guinean Franc (FG)", value: "gnf" },
    { label: "GT - Guatemalan Quetzal (Q)", value: "gtq" },
    { label: "GY - Guyanaese Dollar ($)", value: "gyd" },
    { label: "HK - Hong Kong Dollar (HK$)", value: "hkd" },
    { label: "HN - Honduran Lempira (L)", value: "hnl" },
    { label: "HR - Croatian Kuna (kn)", value: "hrk" },
    { label: "HT - Haitian Gourde (G)", value: "htg" },
    { label: "HU - Hungarian Forint (Ft)", value: "huf" },
    { label: "ID - Indonesian Rupiah (Rp)", value: "idr" },
    { label: "IM - Isle of Man Pound (£)", value: "imp" },
    { label: "IN - Indian Rupee (₹)", value: "inr" },
    { label: "IQ - Iraqi Dinar (د.ع)", value: "iqd" },
    { label: "IR - Iranian Rial (﷼)", value: "irr" },
    { label: "IS - Icelandic Króna (kr)", value: "isk" },
    { label: "JE - Jersey Pound (£)", value: "jep" },
    { label: "JM - Jamaican Dollar (J$)", value: "jmd" },
    { label: "JO - Jordanian Dinar (د.ا)", value: "jod" },
    { label: "JP - Japanese Yen (¥)", value: "jpy" },
    { label: "KE - Kenyan Shilling (KSh)", value: "kes" },
    { label: "KG - Kyrgystani Som (сом)", value: "kgs" },
    { label: "KH - Cambodian Riel (៛)", value: "khr" },
    { label: "KM - Comorian Franc (CF)", value: "kmf" },
    { label: "KR - South Korean Won (₩)", value: "krw" },
    { label: "KW - Kuwaiti Dinar (د.ك)", value: "kwd" },
    { label: "KY - Cayman Islands Dollar ($)", value: "kyd" },
    { label: "KZ - Kazakhstani Tenge (₸)", value: "kzt" },
    { label: "LA - Laotian Kip (₭)", value: "lak" },
    { label: "LB - Lebanese Pound (£)", value: "lbp" },
    { label: "LK - Sri Lankan Rupee (₨)", value: "lkr" },
    { label: "LR - Liberian Dollar ($)", value: "lrd" },
    { label: "LS - Lesotho Loti (L)", value: "lsl" },
    { label: "LY - Libyan Dinar (ل.د)", value: "lyd" },
    { label: "MA - Moroccan Dirham (د.م.)", value: "mad" },
    { label: "MD - Moldovan Leu (L)", value: "mdl" },
    { label: "MG - Malagasy Ariary (Ar)", value: "mga" },
    { label: "MK - Macedonian Denar (ден)", value: "mkd" },
    { label: "MM - Myanma Kyat (Ks)", value: "mmk" },
    { label: "MN - Mongolian Tugrik (₮)", value: "mnt" },
    { label: "MO - Macanese Pataca (MOP$)", value: "mop" },
    { label: "MU - Mauritian Rupee (₨)", value: "mur" },
    { label: "MV - Maldivian Rufiyaa (Rf)", value: "mvr" },
    { label: "MW - Malawian Kwacha (MK)", value: "mwk" },
    { label: "MX - Mexican Peso ($)", value: "mxn" },
    { label: "MY - Malaysian Ringgit (RM)", value: "myr" },
    { label: "MZ - Mozambican Metical (MT)", value: "mzn" },
    { label: "NA - Namibian Dollar (N$)", value: "nad" },
    { label: "NG - Nigerian Naira (₦)", value: "ngn" },
    { label: "NI - Nicaraguan Córdoba (C$)", value: "nio" },
    { label: "NO - Norwegian Krone (kr)", value: "nok" },
    { label: "NP - Nepalese Rupee (₨)", value: "npr" },
    { label: "NZ - New Zealand Dollar ($)", value: "nzd" },
    { label: "OM - Omani Rial (ر.ع.)", value: "omr" },
    { label: "PA - Panamanian Balboa (B/.)", value: "pab" },
    { label: "PE - Peruvian Nuevo Sol (S/.)", value: "pen" },
    { label: "PG - Papua New Guinean Kina (K)", value: "pgk" },
    { label: "PH - Philippine Peso (₱)", value: "php" },
    { label: "PK - Pakistani Rupee (₨)", value: "pkr" },
    { label: "PL - Polish Zloty (zł)", value: "pln" },
    { label: "PY - Paraguayan Guarani (₲)", value: "pyg" },
    { label: "QA - Qatari Rial (ر.ق)", value: "qar" },
    { label: "RO - Romanian Leu (lei)", value: "ron" },
    { label: "RS - Serbian Dinar (дин.)", value: "rsd" },
    { label: "RU - Russian Ruble (₽)", value: "rub" },
    { label: "RW - Rwandan Franc (FRw)", value: "rwf" },
    { label: "SA - Saudi Riyal (ر.س)", value: "sar" },
    { label: "SB - Solomon Islands Dollar (SI$)", value: "sbd" },
    { label: "SC - Seychellois Rupee (₨)", value: "scr" },
    { label: "SE - Swedish Krona (kr)", value: "sek" },
    { label: "SG - Singapore Dollar ($)", value: "sgd" },
    { label: "SH - Saint Helena Pound (£)", value: "shp" },
    { label: "SL - Sierra Leonean Leone (Le)", value: "sle" },
    { label: "SO - Somali Shilling (Sh.So.)", value: "sos" },
    { label: "SR - Surinamese Dollar (SRD$)", value: "srd" },
    { label: "SY - Syrian Pound (£S)", value: "syp" },
    { label: "SZ - Swazi Lilangeni (L)", value: "szl" },
    { label: "TH - Thai Baht (฿)", value: "thb" },
    { label: "TJ - Tajikistani Somoni (ЅМ)", value: "tjs" },
    { label: "TM - Turkmenistani Manat (T)", value: "tmt" },
    { label: "TN - Tunisian Dinar (د.ت)", value: "tnd" },
    { label: "TO - Tongan Pa'anga (T$)", value: "top" },
    { label: "TR - Turkish Lira (₺)", value: "try" },
    { label: "TT - Trinidad and Tobago Dollar (TT$)", value: "ttd" },
    { label: "TW - New Taiwan Dollar (NT$)", value: "twd" },
    { label: "TZ - Tanzanian Shilling (TSh)", value: "tzs" },
    { label: "UA - Ukrainian Hryvnia (₴)", value: "uah" },
    { label: "UG - Ugandan Shilling (USh)", value: "ugx" },
    { label: "US - United States Dollar ($)", value: "usd" },
    { label: "UY - Uruguayan Peso ($U)", value: "uyu" },
    { label: "UZ - Uzbekistan Som (so'm)", value: "uzs" },
    { label: "VN - Vietnamese Dong (₫)", value: "vnd" },
    { label: "VU - Vanuatu Vatu (VT)", value: "vuv" },
    { label: "WS - Samoan Tala (WS$)", value: "wst" },
    { label: "XA - Central African CFA Franc (FCFA)", value: "xaf" },
    { label: "XC - East Caribbean Dollar (EC$)", value: "xcd" },
    { label: "XO - West African CFA Franc (CFA)", value: "xof" },
    { label: "XP - CFP Franc (₣)", value: "xpf" },
    { label: "YE - Yemeni Rial (﷼)", value: "yer" },
    { label: "ZA - South African Rand (R)", value: "zar" },
    { label: "ZM - Zambian Kwacha (ZK)", value: "zmw" },

  
]
