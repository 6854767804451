import {colors} from '../../../../../../constants/colors'
export const Hero4Style = {
  color: {
    titleColor: colors.darkColor,
    subTitleColor: colors.darkColor,
  },
  Btn:{
    
      backgroundColorBtn: colors.mainColor,
      colorBtn: 'white',
  }
}
