import { colors } from '../../../constants/colors'

export const JaririStyle = {
  color: {
    title:'#4D4C4C',
    label:'#4D4C4C',
  },
  button: {
    color: 'white',
    backgroundColor: colors.mainColor,
  },
}
