import {colors} from "../../constants/colors"

export const ThanksStyle={
    color:{
        titleColor: colors.darkColor,
        subTitleColor: colors.darkColor,
        Btn: {
          textColor: 'white',
          bgColor: colors.mainColor,
        },
      }
}